export default class MainPingpong {
  public static readonly SET_STATUS = "MAIN_PINGPONG_SET_STATUS";

  public static setStatus(status: boolean): { status: boolean; type: string } {
    return {
      status,
      type: MainPingpong.SET_STATUS,
    };
  }
}
