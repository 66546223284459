export default class Sidebar {
  public static readonly SHOW = "UI_SIDEBAR_SHOW";
  public static readonly HIDE = "UI_SIDEBAR_HIDE";

  public static show() {
    return {
      data: { display: true },
      type: Sidebar.SHOW,
    };
  }

  public static hide() {
    return {
      data: { display: false },
      type: Sidebar.HIDE,
    };
  }
}
