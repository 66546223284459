export default class UserCutaway {
  public static readonly SET_LIST = "USER_CUTAWAY_SET_LIST";
  public static readonly SET_INCOMING_LIST = "USER_CUTAWAY_SET_INCOMING_LIST";
  public static readonly SET_OUTGOING_LIST = "USER_CUTAWAY_SET_OUTGOING_LIST";
  public static readonly INCOMING_COUNTER = "USER_CUTAWAY_INCOMING_COUNTER";
  public static readonly OUTGOING_COUNTER = "USER_CUTAWAY_OUTGOING_COUNTER";
  public static readonly SET_STATUS = "USER_CUTAWAY_SET_STATUS";
  public static readonly SET_ERROR = "USER_CUTAWAY_SET_ERROR";
  public static readonly CLEAR = "USER_CUTAWAY_CLEAR";

  public static setList(data: any) {
    return {
      data,
      type: UserCutaway.SET_LIST,
    };
  }

  public static setIncomingList(data: any) {
    return {
      data,
      type: UserCutaway.SET_INCOMING_LIST,
    };
  }

  public static setOutgoingList(data: any) {
    return {
      data,
      type: UserCutaway.SET_OUTGOING_LIST,
    };
  }

  public static incomingCounter(data: any) {
    return {
      data,
      type: UserCutaway.INCOMING_COUNTER,
    };
  }

  public static outgoingCounter(data: any) {
    return {
      data,
      type: UserCutaway.OUTGOING_COUNTER,
    };
  }

  public static setStatus(data: any) {
    return {
      data,
      type: UserCutaway.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: UserCutaway.SET_ERROR,
    };
  }

  public static clear() {
    return {
      type: UserCutaway.CLEAR,
    };
  }
}
