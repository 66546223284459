import * as Action from "@actions/index";

const InitialState = {
  status: false,
};

export default (state = InitialState, action: { type: string; status: boolean }): any => {
  switch (action.type) {
    case Action.Main.Pingpong.SET_STATUS:
      return { ...state, status: action.status };
    default:
      return state;
  }
};
