export default class NetworkingMember {
  public static readonly SET_LIST = "NETWORKING_MEMBER_SET_LIST";
  public static readonly SET_PAGINATOR = "NETWORKING_MEMBER_SET_PAGINATOR";
  public static readonly SET_STATUS = "NETWORKING_MEMBER_SET_STATUS";
  public static readonly SET_ERROR = "NETWORKING_MEMBER_SET_ERROR";
  public static readonly CLEAR = "NETWORKING_MEMBER_CLEAR";

  public static setList(data: any) {
    return {
      data,
      type: NetworkingMember.SET_LIST,
    };
  }

  public static setPaginator(data: any) {
    return {
      data,
      type: NetworkingMember.SET_PAGINATOR,
    };
  }

  public static setStatus(data: any) {
    return {
      data,
      type: NetworkingMember.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: NetworkingMember.SET_ERROR,
    };
  }

  public static clear() {
    return {
      type: NetworkingMember.CLEAR,
    };
  }
}
