import * as Action from "@actions/index";

const InitialState = {
  status: "waiting",
  count: 0,
  list: null,
  paginator: null,
  error: null,
};

export default function NetworkingUser(state = InitialState, action: any) {
  switch (action.type) {
    case Action.Networking.User.SET_COUNT:
      return { ...state, count: action.data };
    case Action.Networking.User.SET_LIST:
      return { ...state, list: (Array.isArray(state.list) ? state.list : []).concat(action.data) };
    case Action.Networking.User.SET_STATUS:
      return { ...state, status: action.data };
    case Action.Networking.User.SET_PAGINATOR:
      return { ...state, paginator: action.data };
    case Action.Networking.User.SET_ERROR:
      return { ...state, error: action.data };
    case Action.Networking.User.CLEAR:
      return { ...state, list: null };
    default:
      return state;
  }
}
