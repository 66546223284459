import * as Action from "@actions/index";

const InitialState = {
  status: "waiting",
  count: 0,
  data: null,
  list: null,
  paginator: null,
  error: null,
};

export default function NetworkingMember(state = InitialState, action: any) {
  switch (action.type) {
    case Action.Networking.Member.SET_LIST:
      return { ...state, list: (Array.isArray(state.list) ? state.list : []).concat(action.data) };
    case Action.Networking.Member.SET_PAGINATOR:
      return { ...state, paginator: action.data };
    case Action.Networking.Member.SET_STATUS:
      return { ...state, status: action.data };
    case Action.Networking.Member.SET_ERROR:
      return { ...state, error: action.data };
    case Action.Networking.Member.CLEAR:
      return { ...state, list: null };
    default:
      return state;
  }
}
