export default class NetworkingExponentPlatinum {
  public static readonly SET_DATA = "NETWORKING_EXPONENT_PLATINUM_SET_DATA";
  public static readonly SET_STATUS = "NETWORKING_EXPONENT_PLATINUM_SET_STATUS";
  public static readonly SET_ERROR = "NETWORKING_EXPONENT_PLATINUM_SET_ERROR";
  public static readonly CLEAR = "NETWORKING_EXPONENT_PLATINUM_CLEAR";

  public static setData(data: any) {
    return {
      data,
      type: NetworkingExponentPlatinum.SET_DATA,
    };
  }

  public static setStatus(data: any) {
    return {
      data,
      type: NetworkingExponentPlatinum.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: NetworkingExponentPlatinum.SET_ERROR,
    };
  }

  public static clear() {
    return {
      type: NetworkingExponentPlatinum.CLEAR,
    };
  }
}
