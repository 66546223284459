import { combineReducers } from "redux";

import Selected from "./Selected";
import Cutaway from "./Cutaway";
import Event from "./Event";

export default combineReducers({
  Selected,
  Cutaway,
  Event,
});
