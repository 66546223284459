export default class MainDynamic {
  public static readonly SET_LIST = "MAIN_DYNAMIC_SET_LIST";
  public static readonly SET_STATUS = "MAIN_DYNAMIC_SET_STATUS";
  public static readonly SET_ERROR = "MAIN_DYNAMIC_SET_ERROR";
  public static readonly CLEAR = "MAIN_DYNAMIC_CLEAR";

  public static setList(data: unknown): { type: string; data: unknown } {
    return {
      data,
      type: MainDynamic.SET_LIST,
    };
  }

  public static setStatus(data: unknown): { type: string; data: unknown } {
    return {
      data,
      type: MainDynamic.SET_STATUS,
    };
  }

  public static setError(data: unknown): { type: string; data: unknown } {
    return {
      data,
      type: MainDynamic.SET_ERROR,
    };
  }

  public static clear(): { type: string } {
    return {
      type: MainDynamic.CLEAR,
    };
  }
}
