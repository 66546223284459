import * as Action from "@actions/index";

const InitialState = {
  data: null,
  list: null,
};

export default function NetworkingSearch(state = InitialState, action: any) {
  switch (action.type) {
    case Action.Networking.Search.SET_LIST:
      return { ...state, list: (Array.isArray(state.list) ? state.list : []).concat(action.data) };
    case Action.Networking.Search.CLEAR:
      return { ...state, list: null };
    default:
      return state;
  }
}
