import * as Action from "@actions/index";

const InitialState = {
  status: "waiting",
  list: null,
  incomings: null,
  outgoings: null,
  error: null,
  incomingCounter: null,
  outgoingCounter: null,
};

export default function UserCutaway(state = InitialState, action: any) {
  switch (action.type) {
    case Action.User.Cutaway.SET_LIST:
      return { ...state, list: action.data };
    case Action.User.Cutaway.SET_INCOMING_LIST:
      return { ...state, incomings: action.data };
    case Action.User.Cutaway.SET_OUTGOING_LIST:
      return { ...state, outgoings: action.data };
    case Action.User.Cutaway.INCOMING_COUNTER:
      return { ...state, incomingCounter: action.data };
    case Action.User.Cutaway.OUTGOING_COUNTER:
      return { ...state, outgoingCounter: action.data };
    case Action.User.Cutaway.SET_STATUS:
      return { ...state, status: action.data };
    case Action.User.Cutaway.SET_ERROR:
      return { ...state, error: action.data };
    case Action.User.Cutaway.CLEAR:
      return { ...state, list: null };
    default:
      return state;
  }
}
