export default class StandCommon {
  public static readonly SET_COUNT = "STAND_COMMON_SET_COUNT";
  public static readonly SET_STATUS = "STAND_COMMON_SET_STATUS";
  public static readonly SET_ERROR = "STAND_COMMON_SET_ERROR";
  public static readonly CLEAR = "STAND_COMMON_CLEAR";

  public static setCount(data: any) {
    return {
      data,
      type: StandCommon.SET_COUNT,
    };
  }

  public static setStatus(data: any) {
    return {
      data,
      type: StandCommon.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: StandCommon.SET_ERROR,
    };
  }

  public static clear() {
    return {
      type: StandCommon.CLEAR,
    };
  }
}
