export default class NetworkingSearch {
  public static readonly SET_LIST = "NETWORKING_SEARCH_SET_LIST";
  public static readonly CLEAR = "NETWORKING_SEARCH_CLEAR";

  public static setList(data: any) {
    return {
      data,
      type: NetworkingSearch.SET_LIST,
    };
  }

  public static clear() {
    return {
      type: NetworkingSearch.CLEAR,
    };
  }
}
