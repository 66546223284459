import * as Action from "@actions/index";

export default function Alert(state = Array(), action: any) {
  switch (action.type) {
    case Action.Ui.Alert.OPEN:
      action.data.data.index = new Date().getTime() + state.length;
      return [...state, action.data];
    case Action.Ui.Alert.CLOSE:
      const newState = state.filter((alert: any) => {
        return alert.data.index !== action.data;
      });
      return newState;
    case Action.Ui.Alert.CLOSE_ALL:
      return [];
    default:
      return state;
  }
}
