export default class Event {
  public static readonly SET_DATA = "EVENT_SET_DATA";
  public static readonly SET_COUNT = "EVENT_SET_COUNT";
  public static readonly SET_STATUS = "EVENT_SET_STATUS";
  public static readonly SET_ERROR = "EVENT_SET_ERROR";
  public static readonly CLEAR = "EVENT_CLEAR";

  public static setData(data: any) {
    return {
      data,
      type: Event.SET_DATA,
    };
  }

  public static setCount(data: any) {
    return {
      data,
      type: Event.SET_COUNT,
    };
  }

  public static setStatus(data: any) {
    return {
      data,
      type: Event.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: Event.SET_ERROR,
    };
  }

  public static clear() {
    return {
      type: Event.CLEAR,
    };
  }
}
