import { combineReducers } from "redux";

import Modal from "./Modal";
import Alert from "./Alert";
import Header from "./Header";
import Footer from "./Footer";
import Userbar from "./Userbar";
import Sidebar from "./Sidebar";

export default combineReducers({
  Modal,
  Alert,
  Header,
  Footer,
  Userbar,
  Sidebar,
});
