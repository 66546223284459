import * as Action from "@actions/index";

const InitialState = {
  status: "waiting",
  count: null,
  error: "",
};

export default function Cutaway(state = InitialState, action: any) {
  switch (action.type) {
    case Action.Cutaway.SET_COUNT:
      return { ...state, count: action.data };
    case Action.Cutaway.SET_STATUS:
      return { ...state, status: action.data };
    case Action.Cutaway.SET_ERROR:
      return { ...state, error: action.data };
    case Action.Cutaway.CLEAR:
      return { ...state, ...InitialState };
    default:
      return state;
  }
}
