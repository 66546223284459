import { combineReducers } from "redux";

import Selected from "./Selected";
import Common from "./Common";
import Direction from "./Direction";

export default combineReducers({
  Selected,
  Common,
  Direction,
});
