import { combineReducers } from "redux";

import User from "./User";
import Employee from "./Employee";
import Company from "./Company";
import Exponent from "./Exponent";
import Member from "./Member";
import Search from "./Search";

export default combineReducers({
  User,
  Company,
  Employee,
  Member,
  Exponent,
  Search,
});
