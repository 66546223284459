export default class Cutaway {
  public static readonly SET_COUNT = "CUTAWAY_SET_COUNT";
  public static readonly SET_STATUS = "CUTAWAY_SET_STATUS";
  public static readonly SET_ERROR = "CUTAWAY_SET_ERROR";
  public static readonly CLEAR = "CUTAWAY_CLEAR";

  public static setCount(data: any) {
    return {
      data,
      type: Cutaway.SET_COUNT,
    };
  }

  public static setStatus(data: any) {
    return {
      data,
      type: Cutaway.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: Cutaway.SET_ERROR,
    };
  }

  public static clear() {
    return {
      type: Cutaway.CLEAR,
    };
  }
}
