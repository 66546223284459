import * as Action from "@actions/index";

const InitialState = {
  display: true,
};

export default function Sidebar(state = InitialState, action: any) {
  switch (action.type) {
    case Action.Ui.Sidebar.SHOW:
    case Action.Ui.Sidebar.HIDE:
      return { ...state, ...action.data };
    default:
      return state;
  }
}
