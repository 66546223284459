import * as Action from "@actions/index";

export default function Modal(state = Array(), action: any) {
  switch (action.type) {
    case Action.Ui.Modal.OPEN:
      document.body.classList.add("no-scroll");
      return [...state, action.data];
    case Action.Ui.Modal.CLOSE:
      if (!(state.length - 1)) {
        document.body.classList.remove("no-scroll");
      }
      return [...state.slice(0, -1)];
    case Action.Ui.Modal.CLOSE_ALL:
      document.body.classList.remove("no-scroll");
      return [];
    default:
      return state;
  }
}
