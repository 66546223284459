export default class NetworkingCompany {
  public static readonly SET_COUNT = "NETWORKING_COMPANY_SET_COUNT";
  public static readonly SET_DATA = "NETWORKING_COMPANY_SET_DATA";
  public static readonly SET_STATUS = "NETWORKING_COMPANY_SET_STATUS";
  public static readonly SET_ERROR = "NETWORKING_COMPANY_SET_ERROR";
  public static readonly CLEAR = "NETWORKING_COMPANY_CLEAR";

  public static setCount(data: any) {
    return {
      data,
      type: NetworkingCompany.SET_COUNT,
    };
  }

  public static setData(data: any) {
    return {
      data,
      type: NetworkingCompany.SET_DATA,
    };
  }

  public static setStatus(data: any) {
    return {
      data,
      type: NetworkingCompany.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: NetworkingCompany.SET_ERROR,
    };
  }

  public static clear() {
    return {
      type: NetworkingCompany.CLEAR,
    };
  }
}
