export default class UserEvent {
  public static readonly SET_LIST = "USER_EVENT_SET_LIST";
  public static readonly SET_INCOMING_WAITING = "USER_EVENT_SET_INCOMING_WATING";
  public static readonly SET_INCOMING_APPROVED = "USER_EVENT_SET_INCOMING_APPROVED";
  public static readonly SET_INCOMING_DECLINED = "USER_EVENT_SET_INCOMING_DECLINED";
  public static readonly SET_OUTGOING_WAITING = "USER_EVENT_SET_OUTGOING_WATING";
  public static readonly SET_OUTGOING_APPROVED = "USER_EVENT_SET_OUTGOING_APPROVED";
  public static readonly SET_OUTGOING_DECLINED = "USER_EVENT_SET_OUTGOING_DECLINED";
  public static readonly INCOMING_COUNTER = "USER_EVENT_INCOMING_COUNTER";
  public static readonly OUTGOING_COUNTER = "USER_EVENT_OUTGOING_COUNTER";
  public static readonly SET_STATUS = "USER_EVENT_SET_STATUS";
  public static readonly SET_ERROR = "USER_EVENT_SET_ERROR";
  public static readonly CLEAR = "USER_EVENT_CLEAR";

  public static setList(data: any) {
    return {
      data,
      type: UserEvent.SET_LIST,
    };
  }

  public static setIncomingWaiting(data: any) {
    return {
      data,
      type: UserEvent.SET_INCOMING_WAITING,
    };
  }
  public static setIncomingApproved(data: any) {
    return {
      data,
      type: UserEvent.SET_INCOMING_APPROVED,
    };
  }
  public static setIncomingDeclined(data: any) {
    return {
      data,
      type: UserEvent.SET_INCOMING_DECLINED,
    };
  }

  public static setOutgoingWaiting(data: any) {
    return {
      data,
      type: UserEvent.SET_OUTGOING_WAITING,
    };
  }
  public static setOutgoingApproved(data: any) {
    return {
      data,
      type: UserEvent.SET_OUTGOING_APPROVED,
    };
  }
  public static setOutgoingDeclined(data: any) {
    return {
      data,
      type: UserEvent.SET_OUTGOING_DECLINED,
    };
  }

  public static incomingCounter(data: any) {
    return {
      data,
      type: UserEvent.INCOMING_COUNTER,
    };
  }

  public static outgoingCounter(data: any) {
    return {
      data,
      type: UserEvent.OUTGOING_COUNTER,
    };
  }

  public static setStatus(data: any) {
    return {
      data,
      type: UserEvent.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: UserEvent.SET_ERROR,
    };
  }

  public static clear() {
    return {
      type: UserEvent.CLEAR,
    };
  }
}
