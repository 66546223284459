import * as Action from "@actions/index";

const InitialState = {
  status: "waiting",
  count: null,
  error: null,
};

export default function StandCommon(state = InitialState, action: any) {
  switch (action.type) {
    case Action.Stand.Common.SET_COUNT:
      return { ...state, count: action.data };
    case Action.Stand.Common.SET_STATUS:
      return { ...state, status: action.data };
    case Action.Stand.Common.SET_ERROR:
      return { ...state, error: action.data };
    case Action.Stand.Common.CLEAR:
      return { ...state, data: null };
    default:
      return state;
  }
}
