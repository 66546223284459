import * as Action from "@actions/index";

const InitialState = {
  status: "waiting",
  list: null,
  incomingWaiting: [],
  incomingApproved: [],
  incomingDeclined: [],
  outgoingWaiting: [],
  outgoingApproved: [],
  outgoingDeclined: [],
  error: null,
  incomingCounter: null,
  outgoingCounter: null,
};

export default function UserEvent(state = InitialState, action: any) {
  switch (action.type) {
    case Action.User.Event.SET_LIST:
      return { ...state, list: action.data };
    case Action.User.Event.SET_INCOMING_WAITING:
      return { ...state, incomingWaiting: action.data };
    case Action.User.Event.SET_INCOMING_APPROVED:
      return { ...state, incomingApproved: action.data };
    case Action.User.Event.SET_INCOMING_DECLINED:
      return { ...state, incomingDeclined: action.data };
    case Action.User.Event.SET_OUTGOING_WAITING:
      return { ...state, outgoingWaiting: action.data };
    case Action.User.Event.SET_OUTGOING_APPROVED:
      return { ...state, outgoingApproved: action.data };
    case Action.User.Event.SET_OUTGOING_DECLINED:
      return { ...state, outgoingDeclined: action.data };
    case Action.User.Event.INCOMING_COUNTER:
      return { ...state, incomingCounter: action.data };
    case Action.User.Event.OUTGOING_COUNTER:
      return { ...state, outgoingCounter: action.data };
    case Action.User.Event.SET_STATUS:
      return { ...state, status: action.data };
    case Action.User.Event.SET_ERROR:
      return { ...state, error: action.data };
    case Action.User.Event.CLEAR:
      return { ...state, list: null };
    default:
      return state;
  }
}
