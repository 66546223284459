import * as Action from "@actions/index";

const InitialState = {
  status: "loading",
  preurl: "/",
  preurl_status: "",
  email: "",
  data: null,
};

export default function Auth(state = InitialState, action: any) {
  switch (action.type) {
    case Action.Auth.SET_DATA:
      return { ...state, data: action.data };
    case Action.Auth.SET_STATUS:
      return { ...state, status: action.data };
    case Action.Auth.SET_PRE_URL:
      return { ...state, preurl: action.data };
    case Action.Auth.SET_PRE_URL_STATUS:
      return { ...state, preurl_status: action.data };
    case Action.Auth.SET_EMAIL:
      return { ...state, email: action.data };
    default:
      return state;
  }
}
