export default class Alert {
  public static readonly OPEN = "ALERT_OPEN";
  public static readonly CLOSE = "ALERT_CLOSE";
  public static readonly CLOSE_ALL = "ALERT_CLOSE_ALL";

  public static openAlert(data: any) {
    return {
      data,
      type: Alert.OPEN,
    };
  }

  public static close(index: number) {
    return {
      type: Alert.CLOSE,
      data: index,
    };
  }

  public static closeAll() {
    return {
      type: Alert.CLOSE_ALL,
    };
  }
}
