export default class Auth {
  public static readonly SET_STATUS = "AUTH_SET_STATUS";
  public static readonly SET_DATA = "AUTH_SET_DATA";
  public static readonly SET_PRE_URL = "AUTH_SET_PRE_URL";
  public static readonly SET_EMAIL = "AUTH_SET_EMAIL";
  public static readonly SET_PRE_URL_STATUS = "AUTH_SET_PRE_URL_STATUS";

  public static setStatus(data: any) {
    return {
      type: Auth.SET_STATUS,
      data,
    };
  }

  public static setData(data: any) {
    return {
      type: Auth.SET_DATA,
      data,
    };
  }

  public static setPreurl(data: any) {
    return {
      type: Auth.SET_PRE_URL,
      data,
    };
  }

  public static setPreurlStatus(data: any) {
    return {
      type: Auth.SET_PRE_URL_STATUS,
      data,
    };
  }

  public static setEmail(data: any) {
    return {
      type: Auth.SET_EMAIL,
      data,
    };
  }
}
