import * as Action from "@actions/index";

const InitialState = {
  status: "loading",
  sectionList: null,
  list: [],
  modifiedList: {},
  error: "",
};

export default (state = InitialState, action: any) => {
  switch (action.type) {
    case Action.Schedule.SET_SECTION_LIST:
      return { ...state, sectionList: action.data };
    case Action.Schedule.SET_LIST:
      return { ...state, list: action.data };
    case Action.Schedule.SET_MODIFIED_LIST:
      return { ...state, modifiedList: action.data };
    case Action.Schedule.PATCH:
      const list: any = {};

      Object.keys(state.modifiedList).map((events: any) => {
        list[events] = state.modifiedList[events].map((event: any) => {
          if (event.id === action.data.id) {
            return action.data;
          } else {
            return event;
          }
        });
        return state.modifiedList[events];
      });

      return { ...state, modifiedList: list };
    case Action.Schedule.SET_STATUS:
      return { ...state, status: action.data };
    case Action.Schedule.SET_ERROR:
      return { ...state, error: action.data };
    case Action.Schedule.CLEAR:
      return { ...state, data: {} };
    default:
      return state;
  }
};
