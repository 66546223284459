export default class NetworkingUser {
  public static readonly SET_COUNT = "NETWORKING_USER_SET_COUNT";
  public static readonly SET_PAGINATOR = "NETWORKING_USER_SET_PAGINATOR";
  public static readonly SET_LIST = "NETWORKING_USER_SET_LIST";
  public static readonly SET_STATUS = "NETWORKING_USER_SET_STATUS";
  public static readonly SET_ERROR = "NETWORKING_USER_SET_ERROR";
  public static readonly CLEAR = "NETWORKING_USER_CLEAR";

  public static setCount(data: any) {
    return {
      data,
      type: NetworkingUser.SET_COUNT,
    };
  }

  public static setPaginator(data: any) {
    return {
      data,
      type: NetworkingUser.SET_PAGINATOR,
    };
  }

  public static setList(data: any) {
    return {
      data,
      type: NetworkingUser.SET_LIST,
    };
  }

  public static setStatus(data: any) {
    return {
      data,
      type: NetworkingUser.SET_STATUS,
    };
  }

  public static setError(data: any) {
    return {
      data,
      type: NetworkingUser.SET_ERROR,
    };
  }

  public static clear() {
    return {
      type: NetworkingUser.CLEAR,
    };
  }
}
