import { combineReducers } from "redux";

import General from "./General";
import Platinum from "./Platinum";
import Gold from "./Gold";
import Silver from "./Silver";

export default combineReducers({
  General,
  Platinum,
  Gold,
  Silver,
});
